
// Vue reactivity
import { computed, ref, watch } from 'vue';

// icons
import { notifications, notificationsOffOutline, notificationsOutline, chatbubbleEllipsesOutline, createOutline,
          arrowForward, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonThumbnail,
        IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonBackButton,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
        IonBadge, IonTextarea, IonButton, IonButtons, IonAvatar, IonProgressBar,
        IonRefresher, IonRefresherContent, IonNote, IonItemDivider,
        loadingController, alertController, modalController, onIonViewDidEnter, onIonViewDidLeave, } from '@ionic/vue';
import NotificationItem from "@/components/NotificationItem.vue";
import ImageSlides from "@/components/ImageSlides.vue";
import ProjectFileModal from "@/components/modals/ProjectFileModal.vue";

// API services
import { utils } from '@/composables/utils';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import ProjectService from '@/services/ProjectService';
import { useQRCodeScanner } from '@/composables/useQRCodeScanner';

export default {
  name: 'ProjectDetailPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonThumbnail,
                IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonBackButton,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonBadge, IonTextarea, IonButton, IonButtons, IonAvatar, IonProgressBar,
                IonRefresher, IonRefresherContent, IonNote, IonItemDivider, NotificationItem, ImageSlides, },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const route = useRoute();
    const { id: currProjectId, parentPath, src } = route.params;

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingProjects);
    const project = computed(() => store.getters.getProjectById(currProjectId));
    const projectFileTypes = computed(() => store.state.projectFileTypes);

    // methods or filters
    const router = useRouter();
    const { formatDateString, getProjectStatusColor, numberWithCommas, getRelativeDate, PROJECT_STATUSES,
            getProjectDisplayProgress, addResizeUrlParams, getLocalizedStr, tStr, } = utils();
    const { openUserLocationModal } = useQRCodeScanner();
    
    const updateUserSubscribedProject = async (project: any, action = 'subscribe') => {
      const loading = await loadingController.create({});
      await loading.present();
      let { subscribedProjectIds } = currUser.value;
      if (action == 'subscribe') {
        await ProjectService.subscribeProjectNotifications(project.id);
        project.subscribedByUser = true;
        subscribedProjectIds.push(project.id);
      } else {
        await ProjectService.unsubscribeProjectNotifications(project.id);
        project.subscribedByUser = false;
        subscribedProjectIds = subscribedProjectIds.filter(id => id != project.id);
      }
      store.commit('updateUser', { subscribedProjectIds });
      loading.dismiss();
    }

    const presentAlert = async() => {
      const alert = await alertController.create({
          header: t('projectNotFound'),
          buttons: [
            {
              text: t('back'),
              handler: () => {
                router.replace('/projects');
              },
            },
          ],
        });
      return alert.present();
    };

    const openProjectFileModal = async (fileTypeObj: any) => {
      const title = `${project.value.title} ${fileTypeObj.type}`;
      const files = project.value.files.filter(f => f.type == fileTypeObj.id);
      const modal = await modalController.create({
        component: ProjectFileModal,
        componentProps: { title, files },
      });
      return modal.present();
    }


    if (src == 'qrcode' && project.value.id == null) {
      // invalid project ID
      presentAlert();
    }

    const fetchProjectDetails = (e: any = null) => {
      store.dispatch('fetchProjectDetails', { id: currProjectId, e });
    }
    const originalDocTitle = document.title;
    const updateDocumentTitle = () => {
      document.title = `${project.value.title} | ${originalDocTitle}`;
    }
    onIonViewDidEnter(() => {
      if (project.value.id) {
        if (!project.value.fetchedDetails) fetchProjectDetails(); // fetch product details
        updateDocumentTitle();
      }
    });
    watch(project, () => {
      if (!project.value.fetchedDetails) fetchProjectDetails(); // fetch product details
      updateDocumentTitle();
    });
    onIonViewDidLeave(() => {
      document.title = originalDocTitle;
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      notifications, notificationsOffOutline, notificationsOutline, chatbubbleEllipsesOutline, createOutline,
      arrowForward,

      // variables
      locale,
      currUser, userLoggedIn, parentPath, loading, project,
      PROJECT_STATUSES, projectFileTypes,

      // methods
      t, getLocalizedStr, tStr,
      getProjectStatusColor, updateUserSubscribedProject,
      numberWithCommas, formatDateString, getRelativeDate,
      openUserLocationModal, getProjectDisplayProgress,
      openProjectFileModal, addResizeUrlParams,
    }
  },
}
