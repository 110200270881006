
// icons
import { notificationsOutline, clipboardOutline, checkmark, close, } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonBadge, IonIcon, IonThumbnail, } from '@ionic/vue';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "notification",
    "showProjectTitle",
    "extraRouterParams",
  ],
  components: { IonItem, IonLabel, IonBadge, IonIcon, IonThumbnail, },
  setup() {
    const { t } = useI18n();
    const { getRelativeDate } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      notificationsOutline, clipboardOutline,
      checkmark, close,

      // methods
      getRelativeDate,
    }
  }
}
