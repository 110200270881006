
// Vue reactivity
import { defineComponent } from 'vue';

// icons
import { close, document } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
        IonList, IonItem, IonLabel, modalController, loadingController } from '@ionic/vue';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'ProjectFileModal',
  props: ["title", "files"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
                IonList, IonItem, IonLabel, },
  setup() {
    const { downloadFile } = utils();
    const closeModal = async () => { await modalController.dismiss() };
    const showFile = async (url: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      await downloadFile(url);
      loading.dismiss();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, document,
      
      // methods
      closeModal, showFile,
    }
  }
});
